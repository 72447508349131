import React, { useState, useEffect, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { prepareUrl } from '../../utilities/helpers';
import SbEditable from 'storyblok-react';
import SVG from 'react-inlinesvg';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';

const langCodes = {
	en: {
		text: "English",
		flag: `GB`
	},
	fi: {
		text: "Suomi",
		flag: `FI`
	},
	sv: {
		text: `Svenska`,
		flag: `SE`
	},
	de: {
		text: `Deutsch`,
		flag: `DE`
	},
	nl: {
		text: `Nederlands`,
		flag: `NL`
	},
	da: {
		text: `Dansk`,
		flag: `DK`
	},
	es: {
		text: `Español`,
		flag: `ES`
	},
	fr: {
		text: `Français`,
		flag: `FR`
	},
	it: {
		text: `Italiano`,
		flag: `IT`
	},
	no: {
		text: `Norsk`,
		flag: `NO`
	},
	pl: {
		text: `Polski`,
		flag: `PL`
	},
	pt: {
		text: `Português`,
		flag: `PT`
	},
	sl: {
		text: `Slovenščina`,
		flag: `SL`
	},
	cs: {
		text: `Čeština`,
		flag: `CZ`
	},
	zh: {
		text: `中文 (中国)`,
		flag: `CN`
	}
}

const MainNav = (props) => {
	// props.blok comes from the story-blok editor, so that we get Live preview
	// In production, there will be no props.blok, so we use props.nav.items
	const navigation = props.blok ? props.blok.main_navigation : props.nav.items;
	const translations = props.blok ? [] : props.nav.finalised_translations;
	const lang = props.blok ? props.blok.lang : props.nav.lang;
	const properPage = props.blok ? props.blok.properPage : props.nav.properPage;
	const [hideNav, toggleNav] = useState(true);

	const siblings = props.siblings;

	useEffect(() => {
		document.querySelector(`body`).classList.remove(`overflow-hidden`);
	}, [])

	const toggleNavVisibility = () => {
		if(document){
			if(!hideNav){
				document.querySelector(`body`).classList.remove(`overflow-hidden`);
			} else {
				document.querySelector(`body`).classList.add(`overflow-hidden`);
			}
		}
		toggleNav(!hideNav);
	};

	return (
		<SbEditable content={navigation}>
			<nav className={`navbar navbar-expand-lg ${props.light && `navbar-light`}`}>

				{/* Main logo --> */}
				<Link to="/" className="navbar-brand" >
					<SVG src="https://a.storyblok.com/f/72378/x/bf4923138a/salto-ks-logo.svg" className="main-logo"/>
				</Link>

				<MobileToggle toggle={() => toggleNavVisibility()} open={!hideNav}/>
	
				<Menu
					items={navigation}
					hidden={hideNav} 
					lang={lang}
					properPage={properPage}
					siblings={siblings}
					translations={translations}
				/>
			
			</nav>
		</SbEditable>
	);
};

const getLink = (item, lang, properPage) => {
	
	if(item.link.linktype === `story`){
		let linkbase = ``;
		
		if(item.link.cached_url.charAt(0) !== `/`){
			linkbase += `/`;
		}

		if(!properPage && lang !== `default`){
			linkbase += `${lang}/`;
		}

		return `${linkbase}${item.link.cached_url}`;
	} else {
		return item.link.cached_url;
	}
};

const SingleLink = (item, lang, properPage = false, last = false) => (
	<li className="nav-item menu-elem" key={item._uid}>
		{
			item.link.linktype === `url`
				? <a href={item.link.url} className={item.class} target="_blank" rel="noopener noreferer">{item.title}</a>
				: <Link to={getLink(item, lang, properPage)}>
					{
						last && <i className="fa fa-arrow-right text-blue mr-2" />
					}
					{
						item.advanced && item.advanced.showSettings ? (
							<>
								<span>{item.advanced.title} </span>
								<span style={{color: item.advanced.color}}>
									{item.advanced.highlight}
								</span>
							</>
						) : (<span>{item.title}</span>)
					}
					{item.description && <span className="d-block menu-subtitle">{item.description}</span>}
				</Link>
		}
	</li>
);

const MobileToggle = ({toggle, open}) => (
	<button 
		className="navbar-toggler navbar-toggler-right" 
		type="button"
		aria-controls="navbarNavDropdown" 
		aria-expanded="false" 
		aria-label="Toggle navigation"
		onClick={()=> toggle()}
	>
		<div className={`my-hamburger ${open && `open`}`}>
			<span></span>
			<span></span>
			<span></span>
		</div>
	</button>
);

const Menu = ({items, lang, hidden, properPage, siblings, translations}) => {

	const flagSwitcher = useRef();

	let siblingKeys;
	if(siblings){
		siblingKeys = Object.keys(siblings);
	}

	useEffect(() => {
		if(siblingKeys && siblingKeys.length && lang){
			if(lang === `default`){
				lang = `en`;
			}
			let defaultLang = langCodes[lang].flag;
			flagSwitcher.current.updateSelected(defaultLang);
		}
	}, []);


	let flagsList = [];
	let nameList = {};
	

	if(siblingKeys && siblingKeys.length && lang){

		siblingKeys.map(key => {
			let selected = langCodes[siblings[key].lang];
			if(selected){
				flagsList.push(selected.flag);
				nameList[selected.flag] = selected.text;
			}
		});
	}

	if(translations && !translations.includes(lang)){
		let selected = langCodes[lang];
		if(selected){
			flagsList.push(selected.flag);
			nameList[selected.flag] = selected.text;
		}
		
	}

	const changeLanguage = (code) => {
		Object.keys(langCodes).forEach(lang => {
			if(langCodes[lang] && langCodes[lang].flag === code){

				siblingKeys.forEach(siblingKey => {
					if(siblings[siblingKey].lang === lang){
						let slug = siblings[siblingKey].full_slug;
						if(!slug.startsWith(`/`)){
							slug = `/${slug}`
						}
						navigate(slug);
					}
					return;
				})
				return;
			}
		})
	}

	return (
		<div
			className={`${hidden ? `hide-right` : ``} navbar-collapse d-flex align-items-start`}
		>
			<ul id="primary-menu" className="navbar-nav w-100 justify-content-lg-end">
				{items && items.map((item) => {
					if(item.nav_child){
						const hasLast = item.styled_last;
						return (
							<li className={`menu-elem menu-item-has-children ${hasLast ? `has-last`:``}`} key={item._uid}>
								<a href={item.link && item.link.cached_url ? getLink(item, lang, properPage) : `#`} className="pointer">{item.title ? item.title : `Parent`}</a>
								<ul className="sub-menu">
									{item.nav_child.map((child, i) => {
										const isLast = item.nav_child.length - 1 === i && hasLast;
										return SingleLink(child, lang, properPage, isLast);
									})}
								</ul>
									
							</li>
						);
					} else {
						return SingleLink(item, lang, properPage);
					}
				})}

				{ siblings && Object.keys(siblings).length ? 
					<li>
						<ReactFlagsSelect defaultCountry="EN" onSelect={(code) => changeLanguage(code)} alignOptions="left" ref={flagSwitcher} showSelectedLabel={false} countries={flagsList} customLabels={nameList} />
					</li> : ``
				}
			</ul>
		</div>
	);
};

SingleLink.propTypes = {
	title: PropTypes.string,
	path: PropTypes.string,
	id: PropTypes.string
};

MobileToggle.propTypes = {
	toggle: PropTypes.func.isRequired,
	open: PropTypes.bool
};

Menu.propTypes = {
	items: PropTypes.array.isRequired,
	lang: PropTypes.string,
	hidden: PropTypes.bool
};

MainNav.propTypes = {
	nav: PropTypes.shape({
		lang: PropTypes.string,
		items: PropTypes.arrayOf(
			PropTypes.shape({
				link: PropTypes.shape({
					cached_url: PropTypes.string.isRequired
				}),
				title: PropTypes.string.isRequired
			}).isRequired
		),
		nav_child: PropTypes.arrayOf(
			PropTypes.shape({
				link: PropTypes.shape({
					cached_url: PropTypes.string.isRequired
				}),
				title: PropTypes.string.isRequired
			}).isRequired
		)
	}).isRequired,
	blok: PropTypes.shape({
		main_navigation: PropTypes.array
	}),
	light: PropTypes.bool
};

export default MainNav;
